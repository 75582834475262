import { render, staticRenderFns } from "./newRecordedInfo.vue?vue&type=template&id=49324f14&scoped=true&"
import script from "./newRecordedInfo.vue?vue&type=script&lang=js&"
export * from "./newRecordedInfo.vue?vue&type=script&lang=js&"
import style0 from "./newRecordedInfo.vue?vue&type=style&index=0&id=49324f14&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49324f14",
  null
  
)

export default component.exports